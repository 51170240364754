export const towns = [
	'Askola',
	'Espoo',
	'Hanko',
	'Helsinki',
	'Vantaa',
	'Hyvinkää',
	'Inkoo',
	'Järvenpää',
	'Karkkila',
	'Kauniainen',
	'Kerava',
	'Kirkkonummi',
	'Lapinjärvi',
	'Loviisa',
	'Lohja',
	'Myrskylä',
	'Mäntsälä',
	'Nurmijärvi',
	'Pornainen',
	'Pukkila',
	'Porvoo',
	'Raasepori',
	'Sipoo',
	'Siuntio',
	'Tuusula',
	'Vihti',
	'Aura',
	'Kaarina',
	'Koski Tl',
	'Kustavi',
	'Kemiönsaari',
	'Laitila',
	'Lieto',
	'Loimaa',
	'Parainen',
	'Marttila',
	'Masku',
	'Mynämäki',
	'Naantali',
	'Nousiainen',
	'Oripää',
	'Paimio',
	'Pyhäranta',
	'Pöytyä',
	'Raisio',
	'Rusko',
	'Salo',
	'Sauvo',
	'Somero',
	'Taivassalo',
	'Turku',
	'Uusikaupunki',
	'Vehmaa',
	'Eura',
	'Eurajoki',
	'Harjavalta',
	'Huittinen',
	'Jämijärvi',
	'Kankaanpää',
	'Karvia',
	'Kokemäki',
	'Merikarvia',
	'Nakkila',
	'Pomarkku',
	'Pori',
	'Rauma',
	'Siikainen',
	'Säkylä',
	'Ulvila',
	'Forssa',
	'Hattula',
	'Hausjärvi',
	'Humppila',
	'Hämeenlinna',
	'Janakkala',
	'Jokioinen',
	'Loppi',
	'Riihimäki',
	'Tammela',
	'Ypäjä',
	'Akaa',
	'Hämeenkyrö',
	'Ikaalinen',
	'Juupajoki',
	'Kangasala',
	'Kihniö',
	'Kuhmoinen',
	'Lempäälä',
	'Mänttä-Vilppula',
	'Nokia',
	'Orivesi',
	'Parkano',
	'Pirkkala',
	'Punkalaidun',
	'Pälkäne',
	'Ruovesi',
	'Sastamala',
	'Tampere',
	'Urjala',
	'Valkeakoski',
	'Vesilahti',
	'Virrat',
	'Ylöjärvi',
	'Asikkala',
	'Hartola',
	'Hollola',
	'Heinola',
	'Iitti',
	'Kärkölä',
	'Lahti',
	'Orimattila',
	'Padasjoki',
	'Sysmä',
	'Hamina',
	'Kotka',
	'Kouvola',
	'Miehikkälä',
	'Pyhtää',
	'Virolahti',
	'Imatra',
	'Lappeenranta',
	'Lemi',
	'Luumäki',
	'Parikkala',
	'Rautjärvi',
	'Ruokolahti',
	'Savitaipale',
	'Taipalsaari',
	'Enonkoski',
	'Hirvensalmi',
	'Juva',
	'Kangasniemi',
	'Mikkeli',
	'Mäntyharju',
	'Pertunmaa',
	'Pieksämäki',
	'Puumala',
	'Rantasalmi',
	'Savonlinna',
	'Sulkava',
	'Iisalmi',
	'Joroinen',
	'Kaavi',
	'Keitele',
	'Kiuruvesi',
	'Kuopio',
	'Lapinlahti',
	'Leppävirta',
	'Pielavesi',
	'Rautalampi',
	'Rautavaara',
	'Siilinjärvi',
	'Sonkajärvi',
	'Suonenjoki',
	'Tervo',
	'Tuusniemi',
	'Varkaus',
	'Vesanto',
	'Vieremä',
	'Heinävesi',
	'Ilomantsi',
	'Joensuu',
	'Juuka',
	'Kitee',
	'Kontiolahti',
	'Outokumpu',
	'Lieksa',
	'Liperi',
	'Nurmes',
	'Polvijärvi',
	'Rääkkylä',
	'Tohmajärvi',
	'Hankasalmi',
	'Joutsa',
	'Jyväskylä',
	'Jämsä',
	'Kannonkoski',
	'Karstula',
	'Keuruu',
	'Kinnula',
	'Kivijärvi',
	'Konnevesi',
	'Kyyjärvi',
	'Laukaa',
	'Luhanka',
	'Multia',
	'Muurame',
	'Petäjävesi',
	'Pihtipudas',
	'Saarijärvi',
	'Toivakka',
	'Uurainen',
	'Viitasaari',
	'Äänekoski',
	'Alajärvi',
	'Alavus',
	'Evijärvi',
	'Ilmajoki',
	'Isojoki',
	'Isokyrö',
	'Karijoki',
	'Kauhajoki',
	'Kauhava',
	'Kuortane',
	'Kurikka',
	'Lappajärvi',
	'Lapua',
	'Seinäjoki',
	'Soini',
	'Teuva',
	'Vimpeli',
	'Ähtäri',
	'Kaskinen',
	'Korsnäs',
	'Kristiinankaupunki',
	'Kruunupyy',
	'Laihia',
	'Luoto',
	'Maalahti',
	'Mustasaari',
	'Närpiö',
	'Pietarsaari',
	'Pedersören kunta',
	'Uusikaarlepyy',
	'Vaasa',
	'Vöyri',
	'Halsua',
	'Kannus',
	'Kaustinen',
	'Kokkola',
	'Lestijärvi',
	'Perho',
	'Toholampi',
	'Veteli',
	'Alavieska',
	'Haapajärvi',
	'Haapavesi',
	'Hailuoto',
	'Ii',
	'Kalajoki',
	'Kempele',
	'Kuusamo',
	'Kärsämäki',
	'Liminka',
	'Lumijoki',
	'Merijärvi',
	'Muhos',
	'Nivala',
	'Oulainen',
	'Oulu',
	'Pudasjärvi',
	'Pyhäjoki',
	'Pyhäjärvi',
	'Pyhäntä',
	'Raahe',
	'Reisjärvi',
	'Sievi',
	'Siikajoki',
	'Vaala',
	'Siikalatva',
	'Taivalkoski',
	'Tyrnävä',
	'Utajärvi',
	'Ylivieska',
	'Hyrynsalmi',
	'Kajaani',
	'Kuhmo',
	'Paltamo',
	'Puolanka',
	'Ristijärvi',
	'Sotkamo',
	'Suomussalmi',
	'Enontekiö',
	'Inari',
	'Kemi',
	'Keminmaa',
	'Kittilä',
	'Kolari',
	'Kemijärvi',
	'Muonio',
	'Pelkosenniemi',
	'Posio',
	'Ranua',
	'Rovaniemi',
	'Salla',
	'Savukoski',
	'Simo',
	'Sodankylä',
	'Tervola',
	'Tornio',
	'Pello',
	'Utsjoki',
	'Ylitornio',
	'Brändö',
	'Eckerö',
	'Finström',
	'Föglö',
	'Geta',
	'Hammarland',
	'Jomala',
	'Kumlinge',
	'Kökar',
	'Lemland',
	'Lumparland',
	'Maarianhamina',
	'Saltvik',
	'Sottunga',
	'Sund',
	'Vårdö',
];
